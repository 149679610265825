import React from 'react'
import { Wrap, Title } from '../components/page/components'
import SEO from '../components/SEO'
import FocusHeader from '../components/global/focus-header'
import FocusBody from '../components/page/focus-body'
import EmailVerify from '../components/account/email-change-verify'

const AccountVerifyEmail = ({ location }) => {
  const { href } = location
  return (
    <Wrap>
      <SEO title="Account Email Verify" />

      <FocusHeader title="Email Change" backUrl="/" />
      <FocusBody>
        <Title>Verify</Title>
        <EmailVerify url={href} />
      </FocusBody>
    </Wrap>
  )
}

export default AccountVerifyEmail
