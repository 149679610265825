import React, { useState, useEffect } from 'react'
import { withFirebase } from '../firebase'
import styled from 'styled-components'
import { AuthUserContext } from '../session'
import { navigate, Link } from 'gatsby'
import { FormBox, FormFields, FormField, FormCopy } from '../page/components'

const INITIAL_STATE = {
  submitting: false,
  submitted: false,
  submitErrors: [],
}

const Verify = ({ url, firebase }) => {
  return (
    <AuthUserContext.Consumer>
      {({ authUser }) =>
        authUser ? (
          <VerifyAuth authUser={authUser} firebase={firebase} url={url} />
        ) : (
          <VerifyNonAuth />
        )
      }
    </AuthUserContext.Consumer>
  )
}

const VerifyAuth = ({ firebase, authUser, url }) => {
  const [status, setStatus] = useState(INITIAL_STATE)
  useEffect(
    () => {
      if (firebase) {
        checkVerify(authUser)
      }
    },
    [firebase]
  )
  const checkVerify = authUser => {
    setStatus(prevState => ({
      ...prevState,
      submitting: true,
      submitted: false,
      submitErrors: [],
    }))

    const urlObject = new URL(url)
    const requestId = urlObject.searchParams.get('token')

    const authUpdateFunc = firebase.functions.httpsCallable(
      'authEmailUpdateVerify'
    )
    const funcData = {
      requestId: requestId,
      studentId: authUser.uid,
    }
    authUpdateFunc(funcData)
      .then(res => {
        console.log('✔ Verified', res)

        setTimeout(() => {
          firebase.doSignOut()
          navigate('/sign-in')
        }, 3000)

        setStatus(prevState => ({
          ...prevState,
          submitting: false,
          submitted: true,
          submitErrors: [],
        }))
      })
      .catch(error => {
        const { message } = error
        setStatus(prevState => ({
          ...prevState,
          submitting: false,
          submitted: true,
          submitErrors: [`We had a problem submitting! ${message}`],
        }))
      })
  }

  let content
  if (status.submitted && status.submitErrors.length > 0) {
    content = (
      <FormCopy>
        <Errors>
          {status.submitErrors.map((error, i) => {
            return <div key={`error-${i}`}>{error}</div>
          })}
        </Errors>
      </FormCopy>
    )
  } else if (status.submitted && status.submitErrors.length === 0) {
    content = (
      <FormCopy>
        Done! You will now need to sign-in with your new email address.
      </FormCopy>
    )
  } else {
    content = (
      <FormCopy>
        Please hold...
        <br />
        <br />
        Your new email is being verified. Once verified you will need to sign-in
        with your new email.
      </FormCopy>
    )
  }
  return (
    <FormBox processing={status.submitting}>
      <FormFields>
        <FormField width={`100%`}>{content}</FormField>
      </FormFields>
    </FormBox>
  )
}

const VerifyNonAuth = () => {
  return (
    <FormBox processing={false}>
      <FormFields>
        <FormField width={`100%`}>
          <FormCopy>
            Please <Link to="/sign-in">sign-in</Link> before verifing your new
            email address. After sign-ing in click the email link again.
          </FormCopy>
        </FormField>
      </FormFields>
    </FormBox>
  )
}

export default withFirebase(Verify)

const Errors = styled.div`
  background: #ff5954;
  color: #fff;
  padding: 1em;
`
